import styled from "styled-components";

import {Loader} from "./Loader";

const Container = styled.button`
  display: inline-block;
  background-color: ${({theme}) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 3px;
  padding: 15px 20px;
  min-height: 45px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: 0px 1px 0 ${({theme}) => theme.colors.primaryDark};
  font-family: ${({theme}) => theme.fonts.secondary};
  transition: all 0.2s;
  cursor: pointer;

  ${({fullWidth}) => fullWidth && `
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  `}

  .icon {
    margin-right: 8px;
    position: relative;
    top: 1px;
    font-size: 10px;
  }

  &:hover {
    background-color: ${({theme}) => theme.colors.primaryDark};
  }

  &:disabled {
    background-color: ${({theme}) => theme.colors.primary};
    opacity: 0.5;
    cursor: default;
  }
`;

export const Button = ({children, fullWidth, isLoading, ...props}) =>
  <Container fullWidth={fullWidth} {...props}>
    {isLoading ? <Loader color="white" /> : children}
  </Container>;

export default Button;
