import {Link} from "react-router-dom";
import styled, {withTheme} from "styled-components";

import Logo from "./Logo";

const FooterContainer = styled.footer`
  border-top: 1px solid ${({theme}) => theme.colors.grey[200]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  transition: border-top 0.2s;
  margin-top: 45px;
  width: 100%;
`;

const FooterNavigation = styled.ul`
  list-style: none;
  padding: 0;
`;

const FooterNavigationItem = styled.li`
  font-size: 11px;
  font-weight: bolder;
  text-transform: uppercase;

  a {
    color: ${({theme}) => theme.colors.grey[250]};
    text-decoration: none;

    &:hover {
      color: ${({theme}) => theme.colors.grey[700]};
    }
  }
`;

export const Footer = withTheme(({theme}) => (
  <FooterContainer>
    <FooterNavigation>
      <FooterNavigationItem>
        <Link to="/privacy-policy">
          Privacy Policy
        </Link>
      </FooterNavigationItem>
    </FooterNavigation>
    <div>
      <Link to="/"><Logo color={theme.colors.grey[250]} size="small" /></Link>
    </div>
  </FooterContainer>
));

export default Footer;