import map from "lodash/map";

/**
 * For use in form `validate` functions. Takes a set of `values` and validates
 * it against the provided Joi `schema`. Returns an object with a key for each
 * field that has an error and value of its description.
 *
 * @param {Object} schema     A `Joi` schema definition (https://joi.dev/api/?v=17.6.0)
 * @param {Object} values     Data to validate against the provided schema
 *
 * @return {Object}           An object with keys for each field that returned errors
 */
export const validate = (schema) => async (values) => {
  const errors = {};

  if (!schema) throw new Error("'schema' required to validate values");

  const validations = schema.validate(values, {abortEarly: false});
  const validationErrors = validations?.error?.details ?? [];

  map(validationErrors, ({message, path}) => (errors[path] = message));

  return errors;
};
