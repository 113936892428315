import {Helmet} from "react-helmet";
import kebabCase from "lodash/kebabCase";

import MarkdownRenderer from "../../components/MarkdownRenderer";

export const Page = ({page}) => (
  <div>
    <Helmet>
      <title>Fernando Lujan | {page.title}</title>
      <link rel="canonical" href={`https://fernandolujan.ca/${kebabCase(page.title)}`} />
      <meta name="description" content={page.metaDescription} />
      <meta name="keywords" content={page.metaTags} />
      <meta name="twitter:title" content={page.title} />
      <meta name="twitter:description" content={page.metaDescription} />
      <meta property="og:title" content={page.title} />
      <meta property="og:description" content={page.metaDescription} />
    </Helmet>
    <MarkdownRenderer>
      {page.body}
    </MarkdownRenderer>
  </div>
);

export default Page;
