import styled, {css} from "styled-components";

import {themeTypes} from "../../theme";

export const Blurb = styled.div(({theme}) => css`
  padding: 24px;
  margin: 28px 0 32px 0;
  border-radius: 6px;
  transition: all 0.2s;

  ${
    theme.type === themeTypes.DARK
      ? css`background: ${theme.colors.grey[100]};`
      : css`background: ${theme.colors.grey[50]};`
  };

  h4 {
    margin-top: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 23px;
  }
`);

export const FormContainer = styled.form`
  position: relative;
`;

export const FormSubmissionResult = styled.div(({theme}) => css`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.25s;
  border-radius: 6px;
  z-index: 1;
  transform: scale(0.8);
  background: ${
    (theme.type === themeTypes.LIGHT)
      ? theme.colors.background
      : theme.colors.grey[50]
  };
  box-shadow: ${
    (theme.type === themeTypes.LIGHT)
      ? "0 8px 18px 0 rgba(0, 0, 0, 0.15)"
      : "0 10px 22px 0 rgba(0, 0, 0, 0.75)"
  };
  border: ${
    (theme.type === themeTypes.LIGHT)
      ? "1px solid transparent"
      : `1px solid ${theme.colors.grey[200]}`
  };
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  &.is-submitted {
    opacity: 1;
    right: 0;
    bottom: 0;
    transform: scale(1);
  }
`);

export const SuccessMessage = styled.div(({theme}) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 3px;
  transition: all 0.2s;

  ${
    (theme.type === themeTypes.LIGHT)
      ? css`
        border: 1px solid ${theme.colors.success.regular};
        color: ${theme.colors.success.dark};
        background: ${theme.colors.success.light};
      `
      : css`
        border: 1px solid ${theme.colors.success.regular};
        color: ${theme.colors.success.light};
        background: ${theme.colors.success.dark};
      `
  }

  .icon {
    margin-right: 12px;
    font-size: 20px;
  }
`);

export const Row = styled.div`
  margin-bottom: 18px;
`;

export const Label = styled.label(({theme, $error}) => css`
  display: inline-block;
  width: auto;
  font-size: 12px;
  font-family: ${theme.fonts.primary};
  color: ${theme.colors.grey[300]};
  margin-left: 3px;
  margin-bottom: 6px;
  transition: all 0.2s;
  position: relative;

  &::before {
    content: "";
    opacity: 0;
    transition: all 0.2s;
    background: ${theme.colors.error};
    border-radius: 3px;
    width: 2px;
    height: 5px;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: -18px;

    ${$error && css`
      width: 10px;
      opacity: 1;
    `}
  }
`);

export const Textbox = styled.input(({theme}) => css`
  background: transparent;
  border: 1px solid ${theme.colors.grey[200]};
  box-shadow: 0 1px 2px 0 rgba(0, 0 , 0, ${theme.type === themeTypes.DARK ? 0.4 : 0.15});
  border-radius: 3px;
  font-size: 16px;
  padding: 10px 12px;
  transition: all 0.2s;
  font-family: ${theme.fonts.primary};
  color: ${theme.colors.grey[700]};
  width: 100%;
  max-width: 260px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  &:hover {
    border-color: ${theme.colors.grey[250]};
  }

  &:focus, &:active {
    outline: none;
    border-color: ${theme.colors.primary};

    ${
      theme.type === themeTypes.DARK && css`
        border-color: #194AA6;
      `
    }
  }

  ${
    theme.type === themeTypes.DARK && css`
      background: ${theme.colors.grey[75]};
    `
  }
`);

export const Textarea = styled.textarea(({theme}) => css`
  background: transparent;
  border: 1px solid ${theme.colors.grey[200]};
  box-shadow: 0 1px 2px 0 rgba(0, 0 , 0, ${theme.type === themeTypes.DARK ? 0.4 : 0.15});
  border-radius: 3px;
  font-size: 16px;
  padding: 10px 12px;
  transition: all 0.2s;
  font-family: ${theme.fonts.primary};
  color: ${theme.colors.grey[700]};
  width: 100%;
  max-width: 480px;
  height: 80px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  &:hover {
    border-color: ${theme.colors.grey[250]};
  }

  &::-webkit-resizer {
    display: none;
  }

  &:focus, &:active {
    outline: none;
    border-color: ${theme.colors.primary};

    ${
      theme.type === themeTypes.DARK && css`
        border-color: #194AA6;
      `
    }
  }

  ${
    theme.type === themeTypes.DARK && css`
      background: ${theme.colors.grey[75]};
    `
  }
`);
