import {useEffect} from "react";
import {withRouter} from 'react-router-dom';

const ScrollToTopComponent = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });

    return () => {
      unlisten();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (null);
}

export const ScrollToTop = withRouter(ScrollToTopComponent);
