import {useEffect} from "react";
import {useLocation} from "react-router-dom";

import {Analytics} from "../analytics";

export const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    Analytics.init();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    Analytics.trackPageViewByPath(currentPath);
  }, [location]);
};
