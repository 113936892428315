import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";
import map from "lodash/map";

import {Loader} from "../../../components/Loader";
import {LoaderContainer} from "../styled-components";
import {selectAllContent} from "../../../store/content";
import ArticlesListSummary from "./ArticlesListSummary";

export const ArticlesList = () => {
  const {isLoading, entities} = useSelector(selectAllContent);

  return (
    <div>
      <Helmet>
        <title>Fernando Lujan | Software Design &amp; Development</title>
        <link rel="canonical" href={`https://fernandolujan.ca/`} />
      </Helmet>
      {isLoading ? (
        <LoaderContainer>
          <Loader size={32} />
        </LoaderContainer>
      ) : map(entities.articles, article => (
        <ArticlesListSummary key={article.urlKey} article={article} />
      ))}
    </div>
  )

}

export default ArticlesList;