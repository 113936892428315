import {Helmet} from "react-helmet";
import {RiChatCheckLine} from "react-icons/ri";
import {useFormik} from "formik";
import {useState} from "react";
import isEmpty from "lodash/isEmpty";
import notify from "react-hot-toast";
import Schema from "joi";

import {Analytics} from "../../analytics";
import {
  Blurb,
  FormContainer,
  FormSubmissionResult,
  Row,
  SuccessMessage,
  Label,
  Textbox,
  Textarea,
} from "./styled-components";
import {validate} from "../../utils/validate"
import {wait} from "../../utils/wait";
import Button from "../../components/Button";

export const Contact = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const defaultFormFields = {
    name: "",
    email: "",
    body: "",
  };

  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: defaultFormFields,
    validate: validate(
      Schema.object({
        name: Schema.string().required(),
        email: Schema.string()
          .email({tlds: {allow: false}})
          .required(),
        body: Schema.string().required(),
      }),
    ),
    onSubmit: async (values) => {
      if (isLoading) return;

      setIsLoading(true);
      Analytics.track({
        category: "Contact form",
        action: "Submission attempt",
      });

      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded"
        };

        // eslint-disable-next-line no-unused-vars
        const [_, response] = await Promise.all([
          wait(500),
          fetch("/", {
            method: "POST",
            headers,
            body: new URLSearchParams({
              "form-name": "contact",
              ...values,
            }).toString(),
          })
        ]);

        if (response.status < 200 || response.status >= 300) {
          Analytics.track({
            category: "Contact form",
            action: "Submission failure",
          });
          throw new Error("Failed to submit contact form");
        }

        setIsSubmitted(true);
        Analytics.track({
          category: "Contact form",
          action: "Submission success",
        });
      } catch (error) {
        console.error(error);
        notify.error(
          "Looks like there was a problem sending your message. Please try again later.",
          {id: "contact-error"},
        );
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <div>
      <form
        autoComplete="off"
        name="contact"
        data-netlify
        data-netlify-honeypot="honey-field"
        noValidate
      >
        <Helmet>
          <title>Fernando Lujan | Contact</title>
          <link rel="canonical" href={`https://fernandolujan.ca/contact`} />
          <meta name="twitter:title" content="Contact" />
          <meta name="twitter:description" content="Contact Fernando Lujan via fernandolujan.ca" />
          <meta property="og:title" content="Contact" />
          <meta property="og:description" content="Contact Fernando Lujan via fernandolujan.ca" />
        </Helmet>
        <h2>Contact</h2>
        <Blurb>
          {/* <h4>Need a Developer?</h4> */}
          <p>
            Leave a brief description of your project or request using the form below. You can also contact me for whatever other reason &#8212;that's fine too. Just don't send promotional e-mails or spam.
          </p>
        </Blurb>
        <FormContainer>
          <FormSubmissionResult className={isSubmitted ? "is-submitted" : ""}>
            <div>
              <SuccessMessage>
                <RiChatCheckLine className="icon" />
                {" "}
                Thanks! Your message has been sent
              </SuccessMessage>
            </div>
          </FormSubmissionResult>
          <Row>
            <div>
              <Label
                htmlFor="form-name"
                $error={touched.name && errors.name}
              >
                Name
              </Label>
            </div>
            <Textbox
              type="text"
              name="name"
              id="form-name"
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Row>
          <Row>
            <div>
              <Label
                htmlFor="form-email"
                $error={touched.email && errors.email}
              >
                Email
              </Label>
            </div>
            <Textbox
              type="email"
              name="email"
              id="form-email"
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Row>
          <Row>
            <div>
              <Label
                htmlFor="form-body"
                $error={touched.body && errors.body}
              >
                Message
              </Label>
            </div>
            <Textarea
              name="body"
              id="form-body"
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Row>
        </FormContainer>
        <Row>
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={!dirty || !isEmpty(errors) || isSubmitted}
            onClick={handleSubmit}
            style={{width: "90px"}}
          >
            Send
          </Button>
        </Row>
        {/* Honeypot Field */}
        <div style={{display: "none"}}>
          <label htmlFor="honey-field">
            If you're human, don't fill this field
          </label>
          <input name="honey-field" />
        </div>
      </form>
    </div>
  )
}

export default Contact;