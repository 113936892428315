import {BsX} from "react-icons/bs";
import {default as notify, Toaster, ToastBar} from "react-hot-toast";
import {useContext} from "react";
import styled, {css, ThemeContext} from "styled-components";

import {themeTypes} from "../theme"

const DismissButtonContainer = styled.div`
  height: 100%;
`;

const DismissButton = styled.button(
  ({theme}) => css`
    all: unset;
    background: ${theme.type === themeTypes.LIGHT ? theme.colors.grey[150] : theme.colors.grey[200]};
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 18px;
    margin-left: 12px;
    transition: all 0.15s;
    cursor: pointer;
    float: right;

    > svg {
      position: relative;
      top: -2px;
      left: -2px;
      fill: ${theme.colors.grey[500]};
    }

    &:hover {
      background: transparent;

      > svg {
        fill: ${theme.colors.grey[0]};
      }
    }
  `,
);

export const Notifications = ({position = "bottom-center"}) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Toaster
      position={position}
      toastOptions={{
        duration: 8000,
        style: {
          maxWidth: "580px",
          background: (themeContext.type === themeTypes.DARK)
            ? themeContext.colors.grey[100]
            : themeContext.colors.grey[0],
          color: themeContext.colors.grey[600],
          lineHeight: "20px",
          padding: "10px 10px 10px 20px",
          borderRadius: "4px",
          boxShadow: "0 4px 10px 0 rgba(0, 0, 30, 0.3)",
        },
      }}
      reverseOrder
    >
      {(toast) => (
        <ToastBar
          toast={toast}
          style={{
            ...toast.style,
            animation: toast.visible
              ? `fade-in-bottom 0.5s ${themeContext.animation.easing}`
              : `fade-out-bottom 1s ${themeContext.animation.easing}`,
          }}
        >
          {({icon, message}) => (
            <>
              {icon}
              {message}
              {toast.type !== "loading" && (
                <DismissButtonContainer>
                  <DismissButton onClick={() => notify.dismiss(toast.id)}>
                    <BsX />
                  </DismissButton>
                </DismissButtonContainer>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};
