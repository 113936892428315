import {DateTime} from "luxon";

/**
 * Returns an ordinal number string for a given number.
 * 
 * e.g.
 * 
 * ordinal(15) -> "15th"
 * ordinal(22) -> "22nd"
 */
const ordinal = (n) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;

  // Of course I know what this does. I absolutely did not steal this from somewhere.
  // How dare you even accuse me of that?
  return n + (s[(v-20)%10] || s[v] || s[0]);
};

export const formatDateOrdinal = (isoDate) => {
  const formattedDate = DateTime
    .fromISO(isoDate)
    .toFormat('LLLL dd, yyyy');

  const dateStartIdx = formattedDate.indexOf(" ") + 1;
  const dateEndIdx = formattedDate.indexOf(",");
  const day = formattedDate.substring(dateStartIdx, dateEndIdx);
  const ordinalDay = ordinal(Number.parseInt(day));

  return formattedDate.replace(`${day},`, `${ordinalDay},`);
}