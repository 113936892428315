import styled, {css} from "styled-components";

export const Container = styled.article`
  margin-bottom: 50px;
`;

export const Heading = styled.header`
  margin-bottom: 15px;

  h1, h2 {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 18px;
    font-weight: normal;
    line-height: 28px;
    text-transform: capitalize;

    @media (min-width: 768px) {
      font-size: 22px;
      font-weight: bold;
    }

    > a {
      text-decoration: none;
      color: ${({theme}) => theme.colors.headline};

      &:hover {
        text-decoration: underline;
      }

      &::before {
        content: "";
        display: block;
        width: 7px;
        height: 15px;
        background-color: ${({theme}) => theme.colors.headline};
        position: absolute;
        margin-left: -13px;
        margin-top: 5px;
        opacity: 0.2;
        transition: opacity 0.2s;
      }

      &::after {
        opacity: 1;
        content: "_";
      }

      &:hover {
        &::before {
          opacity: 0.2
        }

        &::after {
          opacity: 0;
        }
      }

      @media (min-width: 768px) {
        &::before {
          height: 16px;
          margin-left: -22px;
          margin-top: 5px;
          opacity: 1;
        }
      }
    }
  }

  h1 {
    margin-top: 18px;
  }

  time {
    font-family: ${({theme}) => theme.fonts.secondary};
    text-transform: uppercase;
    font-size: 0.7em;
    font-weight: 300;
    color: ${({theme}) => theme.colors.grey[300]};

    .icon {
      font-size: 1.6em;
      position: relative;
      top: 4px;
      margin-right: 6px;
    }
  }
`;

export const ArticleImageContainer = styled.div(({theme}) => css`
  background: ${theme.colors.grey[100]};
  max-width: 370px;
  height: calc(100% - 10px);
  margin-top: 16px;
  margin-bottom: 28px;
  border-radius: 4px;
`);

export const ArticleImage = styled.img(({theme}) => css`
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: -4px;
  opacity: 0;
  animation-name: reveal-smooth;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;

  @media (min-width: 768px) {
    max-width: 370px;
  }
`);

export const ArticleActions = styled.div`
  margin-top: 20px;

  a {
    text-decoration: none;
    font-family: ${({theme}) => theme.fonts.secondary};
    text-transform: uppercase;
    font-size: 11px;
    color: ${({theme}) => theme.colors.grey[300]};
    padding: 7px 14px;
    transition: color 0.2s, border 0.2s;
    margin-left: -14px;

    &:hover {
      color: ${({theme}) => theme.colors.grey[900]};
    }

    .icon {
      margin-right: 8px;
      position: relative;
      top: 2px;
    }
  }
`;

export const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;