import styled from "styled-components";

const Container = styled.a`
  color: ${({theme}) => theme.colors.linkIcon};
  cursor: pointer;
  height: 40px;
  border-radius: 6px;
  transition: 0.25s color;
  padding: 8px;
  margin-top: 10px;

  &:hover {
    color: ${({theme}) => theme.colors.linkIconHover};
  }

  &:active {
    color: ${({theme}) => theme.colors.linkIconActive};
  }
`;

export const IconLink = ({
  children,
  destination,
  target = "_self"
}) => {
  return (
    <Container target={target} href={destination} >
      {children}
    </Container>
  )
}

export default IconLink;