import {useState} from "react";
import styled, {ThemeProvider} from "styled-components";

import {GlobalStyles, themes, themeTypes} from "../theme";
import {Notifications} from "./Notifications";
import {useLocalStorageState} from "../hooks/use-local-storage-state";
import MainContent from "./MainContent";
import SidebarNavigation from "./SidebarNavigation";

const ApplicationContainer = styled.div`
  display: flex;
`;

export const Application = () => {
  const {DARK, LIGHT} = themeTypes;
  const [selectedTheme, setSelectedTheme] = useLocalStorageState('theme', DARK);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleTheme = () =>
    setSelectedTheme(selectedTheme === LIGHT ? DARK : LIGHT);

  return (
    <ThemeProvider theme={themes[selectedTheme]}>
      <GlobalStyles />
      <ApplicationContainer>
        <SidebarNavigation
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        />
        <MainContent
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          selectedTheme={selectedTheme}
          toggleTheme={toggleTheme}
        />
      </ApplicationContainer>
      <Notifications />
    </ThemeProvider>
  )
};

export default Application;
