export const themeTypes = Object.freeze({
  DARK: 0,
  LIGHT: 1,
});

const baseTheme = {
  dimensions: {
    sidebarWidth: 340,
  },
  fonts: {
    primary: "'OpenSans', Helvetica, sans-serif",
    secondary: "'Cantarell', Helvetica, sans-serif",
    tertiary: "'Questrial', Helvetica, sans-serif",
    code: "'Fira Code', monospace",
  },
  animation:{
    easing: "cubic-bezier(0.76, 0, 0.24, 1)",
  },
};

export const themes = {
  [themeTypes.DARK]: {
    ...baseTheme,
    type: themeTypes.DARK,
    syntaxHighlightTheme: "nord",
    colors: {
      sidebar: {
        siteName: "#EEEEEE",
        siteNameBackground: "#2a2a2a",
        navigationLinkActive: "#FFFFFF",
        navigationLinkHoverBg: "#1d1f20",
      },
      background: "#121214",
      logo: "#EEE",
      blockquoteBorder: "#526375",
      error: "#d2413f",
      link: "#fff",
      linkIcon: '#666666',
      linkIconActive: '#FFFFFF',
      linkIconHover: '#DDDDDD',
      primary: "#2671C0",
      primaryDark: "#0F447A",
      text: "#c3c9cC",
      headline: "#FFF",
      themeToggleSwitchHover: "#E2ECF1",
      codeBlockBackground: "#1e2024",
      codeBlockBorder: "#1c1e23",
      codeBlockText: "#81a1c1",
      grey: {
        50: '#0a0a0a',
        75: "#1d1f21",
        100: '#232628',
        150: '#2a2c2b',
        200: '#333333',
        250: '#555555',
        300: '#AAAAAA',
        600: '#CCCCCC',
        700: '#DDDDDD',
        800: '#EEEEEE',
        900: '#FFFFFF',
      },
      success: {
        light: "#5fbd50",
        dark: "#1a2b18",
        regular: "#348b2b",
      }
    },
  },
  [themeTypes.LIGHT]: {
    ...baseTheme,
    type: themeTypes.LIGHT,
    syntaxHighlightTheme: "nordLight",
    colors: {
      sidebar: {
        siteName: "#EEEEEE",
        siteNameBackground: "#111111",
        navigationLinkActive: "#000000",
        navigationLinkHoverBg: "#e4e5e8",
      },
      background: "#FFFFFF",
      logo: "#2671C0",
      blockquoteBorder: "#c0cad6",
      error: "#dc6361",
      link: "#2671C0",
      linkIcon: '#AAAAAA',
      linkIconActive: '#000000',
      linkIconHover: '#444444',
      primary: "#2671C0",
      primaryDark: "#0F447A",
      text: "#444",
      headline: "#000",
      themeToggleSwitchHover: "#f1b914",
      codeBlockBackground: "#f6f7f9",
      codeBlockBorder: "#f6f7f9",
      codeBlockText: "#4a7bac",
      grey: {
        50: '#f6f6f6',
        100: '#efefef',
        150: '#e2e2e2',
        200: '#d5d5d5',
        250: '#AAAAAA',
        300: '#777777',
        600: '#383838',
        700: '#444444',
        800: '#222222',
        900: '#000000',
      },
      success: {
        dark: "#47983a",
        light: "#e7fbe5",
        regular: "#77d76d",
      },
    },
  },
};
