import {Helmet} from "react-helmet";
import {BiTime} from "react-icons/bi";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import isEmpty from "lodash/isEmpty"
import kebabCase from "lodash/kebabCase"
import styled from "styled-components";

import {Loader} from "../../../components/Loader";
import {
  ArticleImage,
  ArticleImageContainer,
  Container,
  Heading,
  LoaderContainer,
} from "../styled-components";
import {formatDateOrdinal} from "../../../utils/format-date-ordinal";
import {selectArticleByKey} from "../../../store/content";
import MarkdownRenderer from "../../../components/MarkdownRenderer"

const BackLink = styled(Link)`
  font-family: ${({theme}) => theme.fonts.secondary};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 300;
  color: ${({theme}) => theme.colors.grey[700]};

  &:hover {
    text-decoration: underline;
    color: ${({theme}) => theme.colors.grey[800]};
  }
`;

export const ArticleDetail = ({match, ...props}) => {
  const {articleKey} = match.params;
  // const {isLoading} = useSelector(selectAllContent);
  const article = useSelector(selectArticleByKey(articleKey));

  const publishedOn =
    article?.publishDateOverride || article?.sys?.firstPublishedAt;

  if (isEmpty(article)) {
    return (
      <LoaderContainer>
        <Loader size={32} />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      <Helmet>
        <title>Fernando Lujan | {article.title}</title>
        <link rel="canonical" href={`https://fernandolujan.ca/${kebabCase(article.title)}`} />
        <meta name="description" content={article.metaDescription} />
        <meta name="keywords" content={article.metaTags} />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={article.metaDescription} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.metaDescription} />
      </Helmet>
      <Heading>
        <BackLink to="/">&#8592; back to articles</BackLink>
        <h1>
          {article.title}
        </h1>
        <time dateTime="2017-08-15">
          <BiTime className="icon" />
          Published on {formatDateOrdinal(publishedOn)}
        </time>
      </Heading>
      <ArticleImageContainer>
        <ArticleImage
          className="article-image"
          src={article.previewImage.url}
          alt={`Illustration for article: ${article.title}`}
          width={740}
          height={214}
        />
      </ArticleImageContainer>
      <MarkdownRenderer>
        {article.body}
      </MarkdownRenderer>
    </Container>
  );
};

export default ArticleDetail;
