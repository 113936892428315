import {useEffect, useRef} from "react";
import first from "lodash/first";
import styled from "styled-components";

import {themeTypes} from "../theme";

const Container = styled.span`
  cursor: pointer;
  fill: ${({theme}) => theme.colors.linkIcon};
  transition: 0.3s fill;
  user-select: none;

  &:hover {
    fill: ${({theme}) => theme.colors.themeToggleSwitchHover};
  }
`;

const transformSVG = (ref) => {
  const sun = first(ref.current.getElementsByClassName("sun"));
  const mask = first(ref.current.getElementsByClassName("moon-mask"));
  const rays = ref.current.getElementsByClassName("sun-ray");

  sun.classList.toggle("sun-center-enlarged");
  mask.classList.toggle("moon-mask-active");

  for (const ray of rays) {
    ray.classList.toggle("sun-ray-retracted");
  }
}

export const ThemeToggleSwitch = ({selectedTheme, toggleTheme, ...props}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (selectedTheme === themeTypes.DARK) transformSVG(containerRef);
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  const onClick = () => {
    transformSVG(containerRef);
    toggleTheme();
  }

  return (
    <Container onClick={onClick} ref={containerRef} {...props}>
      <svg
        width="43px"
        height="43px"
        version="1.1"
        className="theme-toggle-switch"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <mask id="mask">
            <rect width="100%" height="100%" fill="white" />
            <circle
              className="moon-mask"
              fill="black"
              cx="28"
              cy="8"
              r="11"
            />
          </mask>
        </defs>
        <g>
          <circle className="sun-ray" cx="13" cy="11" r="1" />
          <circle className="sun-ray" cx="21" cy="15" r="1" />
          <circle className="sun-ray" cx="25" cy="23" r="1" />
          <circle className="sun-ray" cx="21" cy="31" r="1" />
          <circle className="sun-ray" cx="13" cy="35" r="1" />
          <circle className="sun-ray" cx="5" cy="31" r="1" />
          <circle className="sun-ray" cx="1" cy="23" r="1" />
          <circle className="sun-ray" cx="5" cy="15" r="1" />
          <circle
            className="sun"
            cx="13"
            cy="23"
            r="8"
            mask="url(#mask)"
          />
        </g>
      </svg>
    </Container>
  );
};

export default ThemeToggleSwitch;