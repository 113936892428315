import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {normalize, schema} from "normalizr";
import kebabCase from "lodash/kebabCase";
import map from "lodash/fp/map";

import {wait} from "../utils/wait";

const initialState = {
  entities: {
    articles: {},
    pages: {},
  },
  ids: {
    articles: [],
    pages: [],
  },
  isLoading: true,
  error: null,
};

export const selectAllContent = state => state.content;
export const selectAllArticles = state => state.content.entities.articles;
export const selectAllPages = state => state.content.entities.pages;

export const selectArticleByKey = (key) =>
  state => state.content.entities.articles[key];

/**
 * @todo Break this request up into individual pages, articles, and paginated articles.
 */
export const fetchAllContent = createAsyncThunk('content/fetchAll', async () => {
  const  {
    REACT_APP_CONTENTFUL_ACCESS_TOKEN: accessToken,
    REACT_APP_CONTENTFUL_SPACE_ID: spaceId,
  } = process.env;

  const graphQuery = `
    {
      articleCollection(
        order: [sys_firstPublishedAt_DESC, publishDateOverride_DESC]
      ) {
        items {
          body,
          previewImage {
            url,
          },
          publishDateOverride,
          summary,
          sys {
            firstPublishedAt,
          },
          title,
          metaTags,
          metaDescription,
        }
      },
      pageCollection {
        items {
          sys {
            firstPublishedAt,
          },
          title,
          body,
          metaTags,
          metaDescription,
        }
      },
    }
  `;

  // eslint-disable-next-line no-unused-vars
  const [_, response] = await Promise.all([
    wait(500),
    window.fetch(
      `https://graphql.contentful.com/content/v1/spaces/${spaceId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          query: graphQuery,
        }),
      }
    ),
  ]);

  const {data} = await response.json();
  const args = [{}, {idAttribute: "urlKey"}];

  const addUrlKey = map(object => ({
    ...object, urlKey: kebabCase(object.title)
  }));

  return normalize({
    articles: addUrlKey(data.articleCollection?.items),
    pages: addUrlKey(data.pageCollection?.items),
  }, {
    articles: [new schema.Entity('articles', ...args)],
    pages: [new schema.Entity('pages', ...args)],
  });
});

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllContent.pending]: (state) => {
      state.isLoading = true;
    },

    [fetchAllContent.fulfilled]: (state, action) => {
      state.entities = action.payload.entities;
      state.ids = action.payload.result;
      state.error = null;
      state.isLoading = false;
    },

    [fetchAllContent.rejected]: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  }
});

export const contentReducer = contentSlice.reducer;