import styled, {css} from "styled-components";

export const RolesTimeline = styled.div``;
export const TimeframeLabel = styled.div(({theme}) => css`
  margin-top: 4px;
  color: ${theme.colors.grey[300]};
`);

export const CompanyName = styled.h5(() => css`
  margin-top: 16px;
  font-size: 15px;
  margin-bottom: 6px;

  @media (min-width: 768px) {
    font-size: 18px;
  }
`);

export const CompanyPhoto = styled.div(({theme}) => css`
  width: 84px;
  height: 84px;
  padding: 10px;
  background: ${theme.colors.grey[150]};
  border-radius: 6px;
  margin-right: 32px;
  border: 1px solid transparent;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  top: -6px;
  left: -6px;
  transition: all 0.2s;
`);

export const CompanyPhotoImgContainer = styled.div(() => css`
  width: 84px;
  height: 84px;
  /* background: white; */
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
`);

export const CompanyPhotoImg = styled.img(({$noPad}) => css`
  width: 84px;
  padding: ${$noPad ? 0 : 10}px;
  border-radius: 6px;
  background: white;
  /* border: none; */
  aspect-ratio: 1 / 1;
`);

export const RoleTimelineEntryContainer = styled.div(({theme}) => css`
  display: flex;
  border-radius: 6px;
  background: ${theme.colors.grey[50]};
  padding: 26px;
  margin-bottom: 16px;
  max-width: 560px;
  transition: all 0.2s, transform 0.2s ${theme.animation.easing};
  cursor: pointer;

  &::before {
    content: " ";
    display: block;
    width: 8px;
    height: 8px;
    position: relative;
    top: 37px;
    left: -30px;
    border-radius: 6px;
    background: ${theme.colors.grey[200]};
    box-shadow: 0 0 0 5px ${theme.colors.background};
    transition: all 0.3s;
  }

  &:hover {
    transform: scale(1.03);

    &::before {
      background: ${theme.colors.primary};
      box-shadow: 0 0 0 6px ${theme.colors.background};
    }
  }
`);

export const ContractDecorator = styled.span(({theme}) => css`
  color: ${theme.colors.grey[300]};
`);
