import ReactMarkdown from "react-markdown";
import styled, {css} from "styled-components";

import CodeBlock from "./CodeBlock";

export const MarkdownRendererContainer = styled(ReactMarkdown)(({theme}) => css`
  color: ${theme.colors.text};
  font-family: ${theme.fonts.primary};
  font-size: 14px;
  line-height: 24px;

  @media (min-width: 768px) {
    max-width: 650px;
  }

  blockquote {
    margin-left: 0;
    padding-left: 25px;
    margin-bottom: 35px;
    border-left: 5px solid ${theme.colors.blockquoteBorder};
  }

  img {
    margin-top: 16px;
    box-shadow: 0 4px 12px 0 rgb(0 0 0 / 25%);
    border-radius: 10px;
    width: 100%;
    max-width: fit-content;
    opacity: 0;
    animation-name: reveal-smooth;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }
`);

export const MarkdownRenderer = (props) =>
  <MarkdownRendererContainer
    {...props}
    components={{
      code({node, inline, className, children, ...props}) {
        const match = /language-(\w+)/.exec(className || '');
        const language = match?.[1] ?? "";

        return inline ? (
          <code className="code-inline" {...props}>
            {children}
          </code>
        ) : (
          <CodeBlock language={language} {...props}>
            {children}
          </CodeBlock>
        )
      }
    }}
  />;

export default MarkdownRenderer;