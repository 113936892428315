import {Route, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import map from "lodash/map";

import {ArticlesList, ArticleDetail} from "./articles";
import {fetchAllContent, selectAllContent} from "../store/content";
import {Contact} from "./contact";
import {Page} from "./pages";
import {ScrollToTop} from "./ScrollToTop";
import {Work} from "./work";
import {useAnalytics} from "../hooks/use-analytics";

export const Router = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {entities} = useSelector(selectAllContent);

  useAnalytics();
  useEffect(() => {
    dispatch(fetchAllContent());
  }, [dispatch]);

  const customPageRoutes = map(entities.pages, (page) => (
    <Route
      key={page.urlKey}
      path={`/${page.urlKey}`}
      render={(props) => <Page {...props} page={page} />}
      exact={true}
    />
  ));

  return (
    <>
      <ScrollToTop />
      <Switch key={location.pathname} location={location}>
        <Route
          component={ArticlesList}
          path="/"
          exact={true}
        />
        <Route
          component={Contact}
          path="/contact"
          exact={true}
        />
        <Route
          component={Work}
          path="/work"
          exact={true}
        />
        {customPageRoutes}
        <Route
          exact={true}
          component={ArticleDetail}
          path="/articles/:articleKey"
        />
      </Switch>
    </>
  );
};

export default Router;