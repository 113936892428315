import ReactGA from "react-ga";

const init = () => {
  const isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === "development");

  ReactGA.initialize(
    process.env.REACT_APP_GOOGLE_ANALYTICS_TOKEN,
    {debug: isDev},
  );
}

/**
 * [track]
 *
 * @param   {Object}  event
 * @param   {String}  event.category
 * @param   {String}  event.action
 * @param   {[String]}  event.label
 * @param   {[Any]}  event.value
 */
const track = (event) =>
  ReactGA.event(event);

const trackPageViewByPath = (path) => {
  ReactGA.set({page: path});
  ReactGA.pageview(path);
}

export const Analytics = {
  init,
  track,
  trackPageViewByPath,
};
