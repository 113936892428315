import {useEffect, useState} from "react";
import isNil from "lodash/isNil";

export const useLocalStorageState = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    const storageValue = window.localStorage.getItem(key);
    return isNil(storageValue) ? defaultValue : JSON.parse(storageValue);
  })

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value])

  return [value, setValue]
};
