import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {useContext} from "react";
import {ThemeContext} from "styled-components";
import * as defaultHighlightStyles from 'react-syntax-highlighter/dist/cjs/styles/prism'

import {nordLight} from './custom-themes/nord-light';

const syntaxHighlightStyles = {
  ...defaultHighlightStyles,
  nordLight,
};

export const CodeBlock = ({language, children, ...props}) => {
  const {syntaxHighlightTheme} = useContext(ThemeContext);

  return (
    <SyntaxHighlighter
      style={syntaxHighlightStyles[syntaxHighlightTheme]}
      language={language}
      PreTag="div"
      children={String(children).replace(/\n$/, '')}
      className="syntax-highlighter"
      {...props}
    />
  );
}

export default CodeBlock;