import styled from "styled-components";

const componentSizeMap = {
  small: {
    containerSize: 42,
    scale: 0.12,
    translateX: -9,
    translateY: -75,
  },
  medium: {
    containerSize: 64,
    scale: 0.125,
    translateX: -11,
    translateY: -65,
  },
  large: {
    containerSize: 128,
    scale: 0.25,
    translateX: 0,
    translateY: 0,
  },
}

const Container = styled.div`
  display: block;
  margin: 0 auto;
  height: ${({size}) => componentSizeMap[size].containerSize}px;
  width: ${({size}) => componentSizeMap[size].containerSize}px;

  path {
    transition: fill 0.2s;
    fill: ${({theme}) => theme.colors.logo};
  }
`;

const Scaled = styled.div`
  transform: ${({size}) => {
    const sizeConfig = componentSizeMap[size];
    return `
      translate(${sizeConfig.translateX}px, ${sizeConfig.translateY}px)
      scale(${sizeConfig.scale})
    `;
  }};
`;

export const Logo = ({color, size}) => {
  return (
    <Container size={size} color={color}>
      <Scaled size={size}>
        <svg width="201px" height="191px" viewBox="0 0 201 191" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g fillRule="evenodd">
            <path d="M0.61234,191 L44.8521158,-1.42108547e-14 L200.759689,-1.42108547e-14 L154.5141,191 L0.61234,191 Z M157.03196,33.9697 C157.1258,33.9697 128.450849,33.9697 71.0066436,33.9697 L61.9862457,77.3702396 L108.313573,77.3702396 C99.8415627,96.4648025 95.5965675,106.021407 95.5785875,106.040054 C95.5656875,106.053454 54.9584794,106.014924 54.9333394,106.040054 C54.9166061,106.056787 51.4214558,122.725965 44.4478886,156.047588 L126.435687,156.047588 L157.03196,33.9697 Z" id="Logo" fillRule="nonzero" />
          </g>
        </svg>
      </Scaled>
    </Container>
  )
}

export default Logo;