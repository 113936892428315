import {css, createGlobalStyle} from "styled-components";

import {hexToRgb} from "../utils/color-converters";
import {themeTypes} from "../theme";

export const GlobalStyles = createGlobalStyle(({theme}) => {
  const focusOutlineColor = hexToRgb(
    theme.type === themeTypes.DARK
      ? theme.colors.primaryDark
      : theme.colors.primary
  );

  return css`
    html {
      box-sizing: border-box;
    }

    *, *:before, *:after {
      box-sizing: inherit;
    }

    html {
      overflow-x: hidden;

      &::after {
        content: "";
        top: 0;
        left: 0;
        display: block;
        position: absolute;
        width: 100vw;
        height: 3px;
        background-color: ${({theme}) => theme.colors.primary};
      }
    }

    body {
      background-color: ${({theme}) => theme.colors.background};
      color: ${({theme}) => theme.colors.text};
      font-family: ${({theme}) => theme.fonts.primary};
      font-size: 14px;
      margin: 0;
      transition: background-color 0.2s, color 0.2s;
    }

    .theme-toggle-switch {
      left: 10px;
      position: relative;

      .sun {transition: 0.3s r; transition-delay: 0.15s;}
      .sun-ray {transition: all 0.3s ${({theme}) => theme.animation.easing};}
      .sun-ray-retracted {cx: 10px; cy: 26px;}
      .sun-center-enlarged {r: 11px;}
      .moon-mask {transition: 0.3s all ${({theme}) => theme.animation.easing};}
      .moon-mask-active {cx: 20px; cy: 18px;}
    }

    p {
      font-family: ${({theme}) => theme.fonts.primary};
    }

    a {
      color: ${({theme}) => theme.colors.link};

      &:hover {
        text-decoration: none;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      font-family: ${({theme}) => theme.fonts.tertiary};
      font-weight: normal;
      color: ${({theme}) => theme.colors.headline};
    }

    h1 {font-size: 20px;}
    h2 {font-size: 18px;}
    h3 {font-size: 16px;}

    h4 {
      font-size: 18px;
      margin-bottom: 14px;
    }

    h5 {
      font-size: 16px;
      margin-bottom: 10px;
    }

    @media (min-width: 768px) {
      h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
      }

      h1 {font-size: 26px;}
      h2 {font-size: 24px;}
    }

    /**
    * Input Focus Outline Override
    */
      *:focus-visible {
      outline: none;

      ${
        theme.type === themeTypes.DARK
          ? css`
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5),
                        0 0 0 3px rgba(
                          ${focusOutlineColor?.r},
                          ${focusOutlineColor?.g},
                          ${focusOutlineColor?.b},
                          0.6
                        ) !important;
          ` : css`
            box-shadow: 0 0 0 3px rgba(
                          ${focusOutlineColor?.r},
                          ${focusOutlineColor?.g},
                          ${focusOutlineColor?.b},
                          0.3
                        ) !important;
          `
      }
    }

    /**
    * Animations
    **/
    @keyframes fade-in { 
      from { opacity: 0; } 
    }

    @keyframes fade-in-bottom {
      0% {
        transform: translateY(50px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @keyframes fade-out-bottom {
      0% {
        transform: translateY(0);
        opacity: 1;
      }
      60% {
        transform: translateY(50px);
        opacity: 0;
      }
      100% {
        transform: translateY(50px);
        opacity: 0;
      }
    }

    @keyframes reveal-smooth {
      0% {
        mask: linear-gradient(90deg, #000 25%, #000000e6 50%, #00000000) 100% 0 / 400% no-repeat;
        opacity: 0;
      }

      100% {
        mask: linear-gradient(90deg, #000 25%, #000000e6 50%, #00000000) 0 / 400% no-repeat;
        opacity: 1;
      }
    }

    /**
     * Syntax Highlighting
     */
    .syntax-highlighter {
      font-family: ${({theme}) => theme.fonts.code} !important;
      font-size: 14px !important;
      border-radius: 5px;
      background: ${({theme}) => theme.colors.codeBlockBackground} !important;

      pre>div, code>span {
        transition: all 0.3s;
      }

      pre>div>code, code>span {
        background: transparent !important;
      }
    }

    .code-inline {
      font-family: ${({theme}) => theme.fonts.code};
      font-size: 14px;
      background: ${({theme}) => theme.colors.codeBlockBackground};
      padding: 2px 3px 1px 3px;
      border-radius: 4px;
      border: 1px solid ${({theme}) => theme.colors.codeBlockBorder};
      transition: all 0.3s;
      color: ${({theme}) => theme.colors.codeBlockText};
    }
  `;
});
