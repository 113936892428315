import {BiTime} from "react-icons/bi";
import {FaEllipsisH} from "react-icons/fa";
import {Link} from "react-router-dom";

import {
  ArticleActions,
  ArticleImage,
  ArticleImageContainer,
  Container,
  Heading,
} from "../styled-components";
import {formatDateOrdinal} from "../../../utils/format-date-ordinal";
import MarkdownRenderer from "../../../components/MarkdownRenderer"

export const ArticlesListSummary = ({article}) => {
  const publishedOn =
    article.publishDateOverride || article.sys.firstPublishedAt;

  return (
    <Container>
      <Heading>
        <h2>
          <Link to={`/articles/${article.urlKey}`}>{article.title}</Link>
        </h2>
        <time dateTime="2017-08-15">
          <BiTime className="icon" />
          Published on {formatDateOrdinal(publishedOn)}
        </time>
      </Heading>
      <Link to={`/articles/${article.urlKey}`}>
        <ArticleImageContainer>
          <ArticleImage
            className="article-image"
            src={article.previewImage.url}
            alt={`Illustration for article: ${article.title}`}
            width={740}
            height={214}
          />
        </ArticleImageContainer>
      </Link>
      <MarkdownRenderer>
        {article.summary}
      </MarkdownRenderer>
      <ArticleActions>
        <Link to={`/articles/${article.urlKey}`}><FaEllipsisH className="icon" /> Read more</Link>
      </ArticleActions>
    </Container>
  )
};

export default ArticlesListSummary;